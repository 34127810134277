// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import "@mantine/core/styles.css?__remix_sideEffect__";
import "@mantine/dates/styles.css?__remix_sideEffect__";
import "@mantine/notifications/styles.css?__remix_sideEffect__";
import fontStyleSheetUrl from '../node_modules/@fontsource-variable/inter/index.css';
import { cssBundleHref } from '@remix-run/css-bundle';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';
import classes from './root.module.css';
import { resolver, theme } from './theme';
import { generateNonce } from './utils/env.server';
import { Anchor, ColorSchemeScript, Container, Group, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { Header } from './components/header';
import { IntlProvider } from 'react-intl';
export const links = () => {
  const data = [{
    rel: 'preload',
    href: fontStyleSheetUrl,
    as: 'style'
  }, {
    rel: 'preload',
    href: cssBundleHref,
    as: 'style'
  }, {
    rel: 'stylesheet',
    href: fontStyleSheetUrl
  }, {
    rel: 'stylesheet',
    href: cssBundleHref
  }, {
    rel: 'icon',
    href: '/favicon.ico',
    sizes: '32x32'
  }];
  return data;
};
export function metaTags({
  title
} = {}) {
  return [{
    title: title ? `${title} | screenwork` : 'screenwork'
  }, {
    name: 'theme-color',
    content: '#111827'
  }];
}
export const meta = () => {
  return metaTags();
};
export async function loader() {
  const cspScriptNonce = generateNonce();
  return {
    cspScriptNonce
  };
}
export default function App() {
  _s();
  const {
    cspScriptNonce
  } = useLoaderData();
  const intlFormats = {
    date: {
      short: {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }
    }
  };
  return <html lang="de">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<Links />
				<ColorSchemeScript />
			</head>
			<body className={classes.body}>
				<IntlProvider locale={'de'} formats={intlFormats} timeZone="Europe/Berlin">
					<MantineProvider theme={theme} cssVariablesResolver={resolver}>
						<Header />
						<Outlet />
						<Container size="xl">
							<Group py="4rem" justify="center">
								<Anchor href="https://www.screenwork.de/kontakt">Kontakt</Anchor>
								<Anchor href="https://www.screenwork.de/impressum">Impressum</Anchor>
								<Anchor href="https://www.screenwork.de/datenschutz">Datenschutz</Anchor>
							</Group>
						</Container>

						<ScrollRestoration nonce={cspScriptNonce} />
						<Scripts nonce={cspScriptNonce} />
						<LiveReload nonce={cspScriptNonce} />
						<Notifications position="bottom-center" zIndex={1000} />
					</MantineProvider>
				</IntlProvider>
			</body>
		</html>;
}
_s(App, "uyErAlN9OJiKnzDzi8TFQ7x6rp4=", false, function () {
  return [useLoaderData];
});
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;