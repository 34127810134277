// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/theme.ts"
);
import.meta.hot.lastModified = "1718830289043.9336";
}
// REMIX HMR END

import { createTheme, CSSVariablesResolver, List, Text } from '@mantine/core';

export const theme = createTheme({
	fontFamily: "'Inter Variable', sans-serif",
	defaultRadius: 'md',
	primaryColor: 'primary',
	colors: {
		primary: [
			'#f3f3ff',
			'#e6e6ed',
			'#cbcbd3',
			'#aeaeb7',
			'#95959f',
			'#868691',
			'#7e7e8b',
			'#6c6c79',
			'#5f606e',
			'#515164',
		],
		twgray: [
			'#f9fafb',
			'#f3f4f6',
			'#e5e7eb',
			'#d1d5db',
			'#9ca3af',
			'#6b7280',
			'#4b5563',
			'#374151',
			'#1f2937',
			'#111827',
		],
	},
	other: {
		primary1: '#228be6',
		primary2: '#F43F5E',
		mainDark: '#111827',
	},
	components: {
		Text: Text.extend({
			defaultProps: {
				fz: 'sm',
			},
		}),
		List: List.extend({
			defaultProps: {
				size: 'sm',
			},
		}),
	},
});

export const resolver: CSSVariablesResolver = theme => ({
	variables: {
		'--color-primary1': theme.other.primary1,
		'--color-primary2': theme.other.primary2,
		'--color-mainDark': theme.other.mainDark,
	},
	light: {
		'--text-color': theme.colors.dark[7],
	},
	dark: {},
});

// --mantine-shadow-xs: 0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), 0 calc(0.0625rem * var(--mantine-scale)) calc(0.125rem * var(--mantine-scale)) rgba(0, 0, 0, 0.1);
// --mantine-shadow-sm: 0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(0.625rem * var(--mantine-scale)) calc(0.9375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(0.4375rem * var(--mantine-scale)) calc(0.4375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale));
// --mantine-shadow-md: 0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(1.25rem * var(--mantine-scale)) calc(1.5625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(0.625rem * var(--mantine-scale)) calc(0.625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale));
// --mantine-shadow-lg: 0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(1.75rem * var(--mantine-scale)) calc(1.4375rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(0.75rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale));
// --mantine-shadow-xl: 0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(2.25rem * var(--mantine-scale)) calc(1.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(1.0625rem * var(--mantine-scale)) calc(1.0625rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale));

// --color-elevation-small: 0 0 0 1px #edeae9, 0 1px 4px 0 rgba(109, 110, 111, 0.08);
// --color-elevation-medium: 0 0 0 1px #edeae9, 0 2px 10px 0 rgba(109, 110, 111, 0.08);
