// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/logo.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/logo.tsx");
}
// REMIX HMR END

import classes from './logo.module.css';
export function LogoSymbol(props) {
  return <svg {...props} id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 500 500">
		<path className={classes.cls1} d="M147.1,354.3c-7.3,5.2-16.3,8.2-25.2,6-9.4-1.9-17.2-8.5-24.9-13.9-12-8.3-24.1-16.3-35.9-24.8-2.8-2.2-5.8-4.1-8.4-6.8-7.6-7.9-3.8-17.2,4.7-22.4,3.3-2.3,6.6-4.6,9.9-6.9l-29.8-21.8c-1.2.8-2.4,1.6-3.6,2.3-3.2,1.9-6,4.2-9.1,6.3-15.5,9.6-30.4,23.8-22.7,43.6,1.7,4.4,4,8.5,7.6,11.6,5.8,4.5,11.4,9.2,17.8,12.9,20.9,14.4,41.7,28.9,63,42.8,11.1,6.5,24.2,9.2,36.9,8.6,7.7.6,15.2-.9,22.7-2.6,12.7-2.9,23.5-10.2,34-17.7.2-.1.4-.3.6-.4l-29.9-21.8c-2.5,1.7-5.1,3.4-7.6,5.1h-.1Z" />
		<path className={classes.cls1} d="M360.3,407.4c-4.1-2.9-8.6-5.4-13.6-5.9-7.5-.8-15.3-.2-21.6,4.2-14.3,9.4-28.5,19.1-42.4,29-15.4,11.1-28.7,24.4-48.5,13.4-5.2-2.8-9.7-6.6-14.5-10-3.5-2.2-7.2-4.3-10.3-7-11-7.7-22.5-15.2-33.2-23.6-6.8-6.5-16.3-6.8-25.1-5.8-6.4,1.4-14.6,4.9-15.8,12.3-.4,3.1,0,6.8,2.3,9.1,2.6,2,5.4,4.6,8,6.3,2.6,1.7,5.8,3.5,8.3,5.6h.2c2.7,1.7,5.3,3.4,7.6,5.4,2.6,1.5,4.9,3.5,7.4,5.1,1.8.9,3.4,2.1,4.9,3.4,1.6,1.4,3.7,2.3,5.5,3.6,1.8,1.4,3.2,3,5.1,4.1,5,2.8,9.7,6.2,14.5,9.3,19.5,13.7,27.9,20.5,53,19.6,23.6-.4,34.6-9.8,52.8-22.5,11.3-8.7,23.3-16.5,34.8-24.9,4.6-3.4,9.6-6.5,14.1-9.9,3.3-2.7,7.8-4.9,9.7-9,2-4,.4-9.2-3.2-11.7h0Z" />
		<path className={classes.cls1} d="M475.1,271c-4-2.5-8.1-5.1-12.1-7.6l-29.3,20.9c3.7,2.3,7.4,4.7,10.8,7.3,1.9,1.4,3.8,3.2,5.1,5.2,3.6,5.6,2.3,12.5-2.6,16.9-13.2,10-27,19.6-40.4,29.3-4.3,3.1-8.6,6.3-13,9.2-3.6,2.3-7.6,5.4-11.6,6.8-15.5,5.5-26.4-2-38.2-10.6l-29.9,21.4c3.5,2.4,7,4.7,10.5,7.1,5.1,3.4,10,7.1,15.9,9.1,16.9,5.9,35.7,7,53.4,3.6,14-2.6,25.5-11.6,36.6-20,14.5-10.6,29.4-20.8,44.4-30.8,10.8-7.7,25.1-16.8,25.3-31.7v-.2c-.4-16.1-12-28-24.9-36h0ZM487.2,329.4h0c1-.9,2.1-1.8,3.1-2.7-1.7,1.7-3,2.7-3,2.7h-.1Z" />
		<path className={classes.cls2} d="M49.7,134.9c8.2-5.3,16.2-10.9,24.4-16.2,4.1-3,8.4-5.5,12.8-7.8,3.1-1.1,6.4-1.9,9.7-2.5,8.5-.9,19.9.9,24.4,9,5.5,9.1-5.8,15.2-12.1,19.5-17.1,11.8-34.5,22.9-51.5,34.9-8.4,5.2-12.3,14.5-4.7,22.4,2.6,2.7,5.6,4.7,8.4,6.8,11.8,8.6,23.9,16.5,35.9,24.8,7.7,5.4,15.4,12,24.9,13.9,8.9,2.2,17.9-.8,25.2-6.1,13.2-8.7,26.2-17.5,39.1-26.5,14.5-10.8,25.9-18.1,44.3-12.3,6.1,1.7,13,7.9,10.6,14.8-2.9,4.9-8.4,8-13,11.4-14.7,9.8-29.4,19.7-44,29.7-10.5,7.5-21.3,14.8-34,17.7-7.4,1.8-15,3.3-22.7,2.6-12.8.6-25.9-2.1-36.9-8.6-21.2-13.9-42.1-28.4-63-42.8-6.4-3.6-12.1-8.3-17.8-12.9-3.6-3-5.9-7.2-7.6-11.6-7.7-19.8,7.2-33.9,22.7-43.6,3.2-2,5.9-4.4,9.2-6.3,5.3-3.2,10.5-7.3,15.6-10.5h.2c0-.1,0,0,0,0v.2Z" />
		<path className={classes.cls2} d="M153.9,314.4c-2.4-2.1-5.7-3.9-8.3-5.6s-5.4-4.3-8-6.3c-2.4-2.3-2.7-6-2.3-9.1,1.1-7.4,9.4-10.9,15.8-12.3,8.8-1,18.3-.6,25.1,5.8,10.7,8.3,22.2,15.9,33.2,23.6,3.1,2.7,6.8,4.8,10.3,7,4.8,3.4,9.3,7.2,14.5,10,19.7,11,33.1-2.3,48.5-13.4,13.9-9.9,28.1-19.6,42.4-29,6.2-4.5,14.1-5.1,21.6-4.2,5.1.5,9.6,3,13.6,5.9,3.6,2.5,5.2,7.8,3.2,11.7-1.9,4.1-6.4,6.2-9.7,9-4.6,3.4-9.5,6.5-14.1,9.9-11.5,8.4-23.5,16.2-34.8,24.9-18.1,12.7-29.2,22.1-52.8,22.5-25.2.9-33.5-5.9-53-19.6-4.8-3.1-9.5-6.5-14.5-9.3-1.9-1.1-3.4-2.7-5.1-4-1.8-1.4-3.8-2.3-5.5-3.6-1.5-1.3-3.1-2.5-4.9-3.4-2.5-1.6-4.8-3.6-7.4-5.1-2.4-2-5-3.7-7.6-5.3h-.2q0-.1,0-.1Z" />
		<path className={classes.cls2} d="M136.9,87.4c-6.5-10.6,4.3-16.3,11.8-21.6,8-5.2,15.9-10.4,23.9-15.7,12.1-8,24.1-16.1,36.3-24,9.9-6.5,21-9.8,32.7-10.7,20.4-2.1,39.8,4.8,56.3,16.8,18.8,12.2,38.1,23.8,56.5,36.7,9.6,5.8,14.8,17.5,2.1,23.4-4.8,3-10.6,3.3-16.1,2.9-13.5-1-23.5-11-34.8-17.6-9.8-6.3-19.6-12.9-29.4-19.1-6.1-4.1-12.4-8.7-19.9-9.9-5.8-1-11.8-.8-17.4.8-4.1,1.2-8.2,3.6-11.9,6.2-4.1,2.5-8,5-12.1,7.8-7.2,4.7-14.3,9.7-21.6,14.2-8.9,5.3-16.8,12.7-27,15.7-10.2,3.1-21.7,2.4-29.1-5.7v-.2h-.3Z" />
		<path className={classes.cls2} d="M341.3,108c14,.6,28.3,11.8,13.1,23.6-15.3,11.1-31.4,21.2-47,31.9-6.4,4.2-12.6,8.8-19.5,12.2-15.9,7.1-33,7.8-50.3,6.6-13.4-1-26.3-5.6-37.3-13.3-14.7-9.6-29.5-19.4-44.3-28.7-7.2-5-19.7-10.8-17.4-21.2,2.3-7.5,11.3-10.8,18.8-10.8,11.9-.7,22.6,5.5,31.8,12.4,9.9,7.1,19.7,13.6,29.5,20,10.1,7,21.9,12.8,34.6,11,8.4-1,15.9-4.8,23-9.4,11.9-7.7,23.8-16,35.6-23.9,8.6-5.9,18.3-11.1,29-10.3h.4Z" />
		<path className={classes.cls2} d="M487.2,208.8s1.3-1,3-2.7c-1,.9-2,1.8-3.1,2.7h.1Z" />
		<path className={classes.cls2} d="M500,186.4c-.4-16.1-12-28-24.9-36-20.9-12.9-40.9-26.9-61.9-39.5-7.1-3.2-14.8-3.2-22.3-2-4.7.9-8.8,3.4-11.7,7.4-6.2,9.4,6.6,17,13.3,21.4,5.5,4.1,11.2,7.1,16.8,10.7,6.7,4.5,13.6,8.6,20.4,12.9,5.1,3.1,10.2,6.2,15,9.8,1.9,1.4,3.8,3.2,5.1,5.2,3.6,5.6,2.3,12.5-2.6,16.9-13.2,10-27,19.6-40.4,29.3-4.3,3.1-8.6,6.3-13,9.2-3.6,2.3-7.6,5.4-11.6,6.8-16.5,5.9-27.8-3-40.5-12.2-5.9-3.8-11.7-7.8-17.5-11.8-5.7-3.3-10.9-7.3-16.4-11-5.3-3.8-10.4-8.1-16.8-9.8-2.5-.6-5-.6-7.5-.6-2.4-.1-4.8-.3-7.1.2-6.4,1.2-12.8,3.8-16,10.1-2.7,5.6,1.8,10.5,6.3,13.3,19,13.6,38.6,26.4,58,39.6,5.1,3.4,10,7.1,15.9,9.1,16.9,5.9,35.7,7,53.4,3.6,14-2.6,25.5-11.6,36.6-20,14.5-10.6,29.4-20.8,44.4-30.8,10.8-7.7,25.1-16.8,25.3-31.7v-.2h-.3Z" />
		</svg>;
}
_c = LogoSymbol;
export function Logo(props) {
  return <svg {...props} version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1500 243.9" style={{
    enableBackground: "new 0 0 1500 243.9"
  }} xml:space="preserve">
		<path className={classes.st0} d="M337.5,174.9c-6.1,0-11.9-0.8-17.3-2.4c-5.4-1.6-10.4-3.9-15-6.9c-4.6-3-8.6-6.5-11.9-10.6l13.3-13.3
			c4,4.9,8.5,8.5,13.7,10.9c5.2,2.4,11,3.5,17.4,3.5c6.4,0,11.4-1.1,14.9-3.3c3.5-2.2,5.3-5.3,5.3-9.3c0-4-1.4-7.1-4.2-9.3
			c-2.8-2.2-6.5-4-10.9-5.5c-4.4-1.4-9.1-2.9-14.1-4.4c-5-1.4-9.7-3.3-14.1-5.6c-4.4-2.3-8.1-5.4-10.9-9.4c-2.8-4-4.2-9.2-4.2-15.8
			c0-6.6,1.6-12.2,4.8-17.1s7.7-8.5,13.4-11.1c5.7-2.6,12.6-3.9,20.7-3.9c8.5,0,16.1,1.5,22.8,4.5c6.6,3,12.1,7.4,16.4,13.4
			l-13.3,13.3c-3.1-4-6.8-7-11.3-9.2c-4.5-2.1-9.6-3.2-15.2-3.2c-6,0-10.5,1-13.6,3.1c-3.1,2.1-4.7,4.9-4.7,8.6s1.4,6.5,4.1,8.5
			c2.7,2,6.4,3.7,10.9,5c4.5,1.4,9.2,2.8,14.1,4.2c4.9,1.5,9.5,3.4,14,5.8c4.4,2.4,8,5.7,10.9,9.8c2.8,4.1,4.2,9.5,4.2,16.3
			c0,10.2-3.6,18.3-10.9,24.3C359.4,171.9,349.7,174.9,337.5,174.9z" />






		<path className={classes.st0} d="M450.4,174.9c-10.7,0-20.3-2.5-29-7.6c-8.6-5-15.4-11.9-20.4-20.5c-5-8.6-7.4-18.2-7.4-28.7
			c0-10.7,2.5-20.3,7.4-28.9c5-8.5,11.8-15.3,20.4-20.3c8.6-5,18.3-7.4,29-7.4c8.4,0,16.2,1.6,23.4,4.7c7.2,3.1,13.4,7.7,18.5,13.6
			l-13.7,13.7c-3.4-4-7.4-7-12.2-9c-4.8-2.1-10.1-3.1-15.9-3.1c-6.9,0-13,1.6-18.3,4.7c-5.3,3.1-9.5,7.4-12.6,12.9
			c-3.1,5.5-4.6,11.8-4.6,19c0,7.2,1.5,13.5,4.6,19c3.1,5.5,7.2,9.8,12.6,13.1c5.3,3.2,11.4,4.8,18.3,4.8c5.8,0,11.1-1,15.9-3.1
			c4.8-2.1,9-5.1,12.5-9l13.5,13.7c-5,5.8-11.2,10.3-18.4,13.5C466.6,173.3,458.7,174.9,450.4,174.9z" />




		<path className={classes.st0} d="M577.7,64.4c-4.4-1.9-9.4-2.9-14.9-2.9c-12.1,0-21.3,4.1-27.7,12.4c-0.6,0.8-1.3,1.7-1.8,2.6V63.8h-20.6v108.8
			h20.6v-62.1c0-9.9,2.4-17.3,7.3-22.2c4.9-4.9,11.1-7.3,18.8-7.3c3.7,0,6.9,0.5,9.6,1.6c2.7,1.1,5.3,2.8,7.6,5.3l13.5-14
			C586.2,69.5,582.1,66.3,577.7,64.4z" />


		<path className={classes.st0} d="M694.8,87.4c-4.4-8.1-10.4-14.4-18.1-19c-7.7-4.6-16.6-6.9-26.7-6.9c-10.5,0-20,2.5-28.5,7.4
			c-8.5,5-15.2,11.7-20.1,20.3c-5,8.6-7.4,18.2-7.4,28.9c0,10.8,2.5,20.5,7.6,29.1c5,8.6,11.9,15.3,20.6,20.3c8.7,5,18.5,7.4,29.3,7.4
			c8.5,0,16.6-1.6,24.2-4.8c7.6-3.2,13.9-7.9,19.1-14l-13.3-13.5c-3.7,4.3-8.1,7.5-13.2,9.6c-5.1,2.1-10.7,3.2-16.8,3.2
			c-7.3,0-13.8-1.6-19.5-4.7c-5.6-3.1-10-7.6-13.1-13.3c-1.9-3.5-3.2-7.4-3.9-11.6h85.2c0.5-2.3,0.8-4.3,0.9-6.1
			c0.2-1.8,0.2-3.4,0.2-4.9C701.3,104.7,699.1,95.5,694.8,87.4z M631,85.1c5.3-3.1,11.5-4.6,18.5-4.6c6.7,0,12.4,1.4,17.2,4.2
			c4.7,2.8,8.4,6.8,11,11.9c1.8,3.5,2.9,7.5,3.5,12H615c0.7-3.9,1.9-7.5,3.6-10.8C621.5,92.5,625.7,88.2,631,85.1z" />





		<path className={classes.st0} d="M817.5,87.4c-4.4-8.1-10.4-14.4-18.1-19c-7.7-4.6-16.6-6.9-26.7-6.9c-10.5,0-20,2.5-28.5,7.4
			c-8.5,5-15.2,11.7-20.1,20.3c-5,8.6-7.4,18.2-7.4,28.9c0,10.8,2.5,20.5,7.6,29.1c5,8.6,11.9,15.3,20.6,20.3c8.7,5,18.5,7.4,29.3,7.4
			c8.5,0,16.6-1.6,24.2-4.8c7.6-3.2,13.9-7.9,19.1-14l-13.3-13.5c-3.7,4.3-8.1,7.5-13.2,9.6c-5.1,2.1-10.7,3.2-16.8,3.2
			c-7.3,0-13.8-1.6-19.5-4.7c-5.6-3.1-10-7.6-13.1-13.3c-1.9-3.5-3.2-7.4-3.9-11.6h85.2c0.5-2.3,0.8-4.3,0.9-6.1
			c0.2-1.8,0.2-3.4,0.2-4.9C824,104.7,821.9,95.5,817.5,87.4z M753.7,85.1c5.3-3.1,11.5-4.6,18.5-4.6c6.7,0,12.4,1.4,17.2,4.2
			c4.7,2.8,8.4,6.8,11,11.9c1.8,3.5,2.9,7.5,3.5,12h-66.2c0.7-3.9,1.9-7.5,3.6-10.8C744.3,92.5,748.4,88.2,753.7,85.1z" />





		<path className={classes.st0} d="M938.5,83.9c-3.7-6.6-8.7-12-15-16.1c-6.3-4.1-13.6-6.2-21.9-6.2c-8.2,0-15.6,1.9-22.2,5.6
			c-4.9,2.8-9.1,6.4-12.4,10.7v-14h-20.6v108.8h20.6v-63.4c0-5.5,1.2-10.4,3.7-14.7c2.4-4.3,5.8-7.6,10.1-10.1
			c4.3-2.4,9.2-3.7,14.7-3.7c8.2,0,15,2.7,20.1,8c5.2,5.3,7.8,12.1,7.8,20.4v63.4H944v-67.5C944,97.6,942.2,90.5,938.5,83.9z" />


		<polygon className={classes.st0} points="1103.8,63.8 1076.1,139.2 1048.7,63.8 1036.8,63.8 1009.3,139.2 981.6,63.8 960,63.8 1003.1,172.6
			1015,172.6 1042.8,100.3 1070.4,172.6 1082.5,172.6 1125.4,63.8 " />

		<path className={classes.st0} d="M1190.2,174.9c-10.7,0-20.3-2.5-28.9-7.6c-8.6-5-15.3-11.9-20.4-20.5c-5-8.6-7.6-18.3-7.6-29
			c0-10.5,2.5-20,7.6-28.5s11.8-15.2,20.4-20.3c8.5-5,18.2-7.6,28.9-7.6c10.5,0,20.1,2.5,28.7,7.4c8.6,5,15.5,11.7,20.5,20.3
			c5,8.6,7.6,18.1,7.6,28.6c0,10.7-2.5,20.3-7.6,29c-5,8.6-11.9,15.5-20.5,20.5C1210.3,172.4,1200.7,174.9,1190.2,174.9z M1190.2,155
			c6.9,0,13-1.6,18.3-4.8c5.3-3.2,9.5-7.6,12.6-13.2c3.1-5.6,4.6-11.9,4.6-19.1c0-7-1.6-13.3-4.7-18.8c-3.1-5.5-7.3-9.8-12.6-12.9
			c-5.3-3.1-11.3-4.7-18.2-4.7s-13,1.6-18.3,4.7c-5.3,3.1-9.5,7.4-12.6,12.9c-3.1,5.5-4.6,11.8-4.6,18.8c0,7.2,1.5,13.5,4.6,19.1
			c3.1,5.6,7.2,10,12.6,13.2C1177.2,153.4,1183.3,155,1190.2,155z" />





		<path className={classes.st0} d="M1335.1,64.4c-4.4-1.9-9.4-2.9-14.9-2.9c-12.1,0-21.3,4.1-27.7,12.4c-0.6,0.8-1.3,1.7-1.8,2.6V63.8h-20.6v108.8
			h20.6v-62.1c0-9.9,2.4-17.3,7.3-22.2c4.9-4.9,11.1-7.3,18.8-7.3c3.7,0,6.9,0.5,9.6,1.6c2.7,1.1,5.3,2.8,7.6,5.3l13.5-14
			C1343.7,69.5,1339.5,66.3,1335.1,64.4z" />


		<polygon className={classes.st0} points="1458.1,172.6 1406,116.1 1456,63.8 1431,63.8 1382.5,115 1382.5,9.1 1361.9,9.1 1361.9,172.6
			1382.5,172.6 1382.5,119 1431.5,172.6 " />

		<path className={classes.st1} d="M101.3,161.3c-3,2.1-6.7,3.4-10.3,2.5c-3.9-0.8-7.1-3.5-10.2-5.7c-4.9-3.4-9.9-6.7-14.7-10.2
			c-1.1-0.9-2.4-1.7-3.4-2.8c-3.1-3.2-1.5-7.1,1.9-9.2c1.4-0.9,2.7-1.9,4.1-2.8l-12.2-8.9c-0.5,0.3-1,0.7-1.5,1
			c-1.3,0.8-2.5,1.7-3.8,2.6c-6.4,3.9-12.5,9.8-9.3,17.9c0.7,1.8,1.6,3.5,3.1,4.7c2.4,1.9,4.7,3.8,7.3,5.3
			c8.5,5.9,17.1,11.8,25.8,17.6c4.5,2.7,9.9,3.8,15.1,3.5c3.1,0.3,6.2-0.4,9.3-1.1c5.2-1.2,9.6-4.2,13.9-7.2c0.1-0.1,0.2-0.1,0.2-0.2
			l-12.3-8.9C103.4,159.9,102.4,160.6,101.3,161.3z" />




		<path className={classes.st1} d="M188.8,183.1c-1.7-1.2-3.5-2.2-5.6-2.4c-3.1-0.3-6.3-0.1-8.9,1.7c-5.9,3.9-11.7,7.8-17.4,11.9
			c-6.3,4.5-11.8,10-19.9,5.5c-2.1-1.1-4-2.7-6-4.1c-1.4-0.9-2.9-1.8-4.2-2.9c-4.5-3.2-9.2-6.3-13.6-9.7c-2.8-2.6-6.7-2.8-10.3-2.4
			c-2.6,0.6-6,2-6.5,5c-0.2,1.3,0,2.8,0.9,3.7c1.1,0.8,2.2,1.9,3.3,2.6c1,0.7,2.4,1.4,3.4,2.3l0.1,0c1.1,0.6,2.2,1.3,3.1,2.2
			c1.1,0.6,2,1.4,3,2.1c0.7,0.4,1.4,0.9,2,1.4c0.7,0.6,1.5,0.9,2.2,1.5c0.7,0.6,1.3,1.2,2.1,1.7c2,1.1,4,2.6,6,3.8
			c8,5.6,11.4,8.4,21.8,8c9.7-0.2,14.2-4,21.6-9.2c4.6-3.6,9.6-6.8,14.3-10.2c1.9-1.4,3.9-2.7,5.8-4.1c1.3-1.1,3.2-2,4-3.7
			C190.9,186.3,190.2,184.1,188.8,183.1z" />





		<path className={classes.st1} d="M235.8,127.1c-1.7-1-3.3-2.1-5-3.1l-12,8.6c1.5,0.9,3,1.9,4.5,3c0.8,0.6,1.6,1.3,2.1,2.1c1.5,2.3,0.9,5.1-1,6.9
			c-5.4,4.1-11.1,8-16.6,12c-1.8,1.3-3.5,2.6-5.3,3.8c-1.5,1-3.1,2.2-4.7,2.8c-6.4,2.3-10.8-0.8-15.7-4.3l-12.3,8.8
			c1.4,1,2.9,1.9,4.3,2.9c2.1,1.4,4.1,2.9,6.5,3.8c6.9,2.4,14.6,2.9,21.9,1.5c5.8-1.1,10.5-4.8,15-8.2c6-4.3,12.1-8.5,18.2-12.6
			c4.4-3.2,10.3-6.9,10.4-13l0-0.1C245.9,135.3,241.1,130.4,235.8,127.1z M240.8,151.1L240.8,151.1c0.4-0.4,0.8-0.7,1.2-1.1
			C241.3,150.7,240.8,151.1,240.8,151.1z" />




		<g>
			<path className={classes.st0} d="M61.4,71.3c3.4-2.2,6.7-4.5,10-6.6c1.7-1.2,3.4-2.2,5.3-3.2c1.3-0.5,2.6-0.8,4-1c3.5-0.4,8.2,0.4,10,3.7
				c2.3,3.7-2.4,6.2-5,8c-7,4.8-14.1,9.4-21.1,14.3c-3.5,2.1-5,6-1.9,9.2c1.1,1.1,2.3,1.9,3.4,2.8c4.8,3.5,9.8,6.8,14.7,10.2
				c3.1,2.2,6.3,4.9,10.2,5.7c3.7,0.9,7.3-0.3,10.3-2.5c5.4-3.5,10.7-7.2,16-10.9c5.9-4.4,10.6-7.4,18.2-5c2.5,0.7,5.3,3.3,4.3,6.1
				c-1.2,2-3.4,3.3-5.3,4.7c-6,4-12.1,8.1-18,12.2c-4.3,3.1-8.7,6.1-13.9,7.2c-3.1,0.7-6.2,1.3-9.3,1.1c-5.2,0.2-10.6-0.9-15.1-3.5
				c-8.7-5.7-17.3-11.6-25.8-17.6c-2.6-1.5-4.9-3.4-7.3-5.3c-1.5-1.2-2.4-2.9-3.1-4.7c-3.2-8.1,2.9-13.9,9.3-17.9
				c1.3-0.8,2.4-1.8,3.8-2.6C57.1,74.4,59.2,72.7,61.4,71.3L61.4,71.3z" />





			<path className={classes.st0} d="M104.1,145c-1-0.9-2.3-1.6-3.4-2.3c-1.1-0.7-2.2-1.8-3.3-2.6c-1-0.9-1.1-2.5-0.9-3.7c0.5-3,3.8-4.5,6.5-5
				c3.6-0.4,7.5-0.2,10.3,2.4c4.4,3.4,9.1,6.5,13.6,9.7c1.3,1.1,2.8,2,4.2,2.9c2,1.4,3.8,3,6,4.1c8.1,4.5,13.6-1,19.9-5.5
				c5.7-4.1,11.5-8.1,17.4-11.9c2.6-1.8,5.8-2.1,8.9-1.7c2.1,0.2,3.9,1.2,5.6,2.4c1.5,1,2.1,3.2,1.3,4.8c-0.8,1.7-2.6,2.6-4,3.7
				c-1.9,1.4-3.9,2.7-5.8,4.1c-4.7,3.4-9.6,6.6-14.3,10.2c-7.4,5.2-12,9.1-21.6,9.2c-10.3,0.4-13.7-2.4-21.8-8c-2-1.3-3.9-2.7-6-3.8
				c-0.8-0.4-1.4-1.1-2.1-1.7c-0.7-0.6-1.6-0.9-2.2-1.5c-0.6-0.5-1.3-1-2-1.4c-1-0.7-2-1.5-3-2.1C106.3,146.3,105.3,145.6,104.1,145
				L104.1,145z" />





			<path className={classes.st0} d="M97.1,51.9c-2.7-4.3,1.8-6.7,4.8-8.8c3.3-2.1,6.5-4.3,9.8-6.5c5-3.3,9.9-6.6,14.9-9.8c4.1-2.7,8.6-4,13.4-4.4
				c8.4-0.9,16.3,2,23.1,6.9c7.7,5,15.6,9.8,23.2,15c3.9,2.4,6.1,7.2,0.9,9.6c-2,1.2-4.4,1.4-6.6,1.2c-5.5-0.4-9.7-4.5-14.3-7.2
				c-4-2.6-8-5.3-12-7.8c-2.5-1.7-5.1-3.6-8.2-4.1c-2.4-0.4-4.8-0.4-7.1,0.3c-1.7,0.5-3.4,1.5-4.9,2.5c-1.7,1-3.3,2.1-5,3.2
				c-3,1.9-5.9,4-8.9,5.8c-3.6,2.2-6.9,5.2-11.1,6.4C104.9,55.5,100.2,55.2,97.1,51.9L97.1,51.9z" />



			<path className={classes.st0} d="M181,60.3c5.8,0.2,11.6,4.9,5.4,9.7c-6.3,4.6-12.9,8.7-19.3,13.1c-2.6,1.7-5.2,3.6-8,5
				c-6.5,2.9-13.5,3.2-20.6,2.7c-5.5-0.4-10.8-2.3-15.3-5.5c-6-3.9-12.1-7.9-18.2-11.8c-2.9-2.1-8.1-4.4-7.1-8.7
				c0.9-3.1,4.6-4.4,7.7-4.4c4.9-0.3,9.3,2.3,13,5.1c4.1,2.9,8.1,5.6,12.1,8.2c4.2,2.9,9,5.3,14.2,4.5c3.5-0.4,6.5-2,9.4-3.8
				c4.9-3.2,9.7-6.6,14.6-9.8C172.5,62.1,176.5,59.9,181,60.3L181,60.3z" />



			<path className={classes.st0} d="M240.8,101.7c0,0,0.5-0.4,1.2-1.1C241.6,100.9,241.2,101.3,240.8,101.7L240.8,101.7z" />
			<path className={classes.st0} d="M246,92.5c-0.2-6.6-4.9-11.5-10.2-14.8c-8.6-5.3-16.8-11-25.4-16.2c-2.9-1.3-6-1.3-9.1-0.8
				c-1.9,0.4-3.6,1.4-4.8,3c-2.5,3.8,2.7,7,5.4,8.8c2.3,1.7,4.6,2.9,6.9,4.4c2.7,1.9,5.6,3.5,8.3,5.3c2.1,1.3,4.2,2.5,6.1,4
				c0.8,0.6,1.6,1.3,2.1,2.1c1.5,2.3,0.9,5.1-1,6.9c-5.4,4.1-11.1,8-16.6,12c-1.8,1.3-3.5,2.6-5.3,3.8c-1.5,1-3.1,2.2-4.7,2.8
				c-6.8,2.4-11.4-1.2-16.6-5c-2.4-1.5-4.8-3.2-7.2-4.8c-2.3-1.4-4.5-3-6.7-4.5c-2.2-1.6-4.3-3.3-6.9-4c-1-0.2-2.1-0.2-3.1-0.2
				c-1-0.1-2-0.1-2.9,0.1c-2.6,0.5-5.2,1.6-6.6,4.2c-1.1,2.3,0.8,4.3,2.6,5.5c7.8,5.6,15.8,10.8,23.8,16.2c2.1,1.4,4.1,2.9,6.5,3.8
				c6.9,2.4,14.6,2.9,21.9,1.5c5.8-1.1,10.5-4.8,15-8.2c6-4.3,12.1-8.5,18.2-12.6C240.1,102.4,245.9,98.6,246,92.5L246,92.5z" />





		</g>
		</svg>;
}
_c2 = Logo;
var _c, _c2;
$RefreshReg$(_c, "LogoSymbol");
$RefreshReg$(_c2, "Logo");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;