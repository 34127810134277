// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/header.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/header.tsx");
}
// REMIX HMR END

import { Container, Group, Button, Select } from '@mantine/core';
import classes from './header.module.css';
import { Logo } from './logo';
import { Link, useMatches, useNavigate } from '@remix-run/react';
const links = [];
export function Header() {
  _s();
  const navigate = useNavigate();
  const matches = useMatches();
  const active = matches[matches.length - 1];
  const items = links.map(link => <Button key={link.to} variant={active.pathname == link.to ? 'outline' : 'transparent'} component={Link} to={link.to}>

			{link.label}
		</Button>);
  return <header className={classes.header}>
			<Container size="md" className={classes.inner}>
				<Logo width={'12rem'} />
				<Group gap={8} visibleFrom="xs">
					{items}
				</Group>
				<Select hiddenFrom="xs" ml="sm" data={links.map(link => ({
        value: link.to,
        label: link.label
      }))} defaultValue={active.pathname} onChange={value => navigate(value)}>
        </Select>
			</Container>
		</header>;
}
_s(Header, "QJvdvw8LSR0ITlTAyNKyJWzg0tY=", false, function () {
  return [useNavigate, useMatches];
});
_c = Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;